<template>
  <div>
    <transition name="fade-three" mode="out-in">
      <router-view />
    </transition>
    <van-tabbar v-if="show" v-model="active" ruote z-index='1000' safe-area-inset-bottom :border=false>
      <van-tabbar-item replace :to="item.path" :name="item.id" v-for="item in tabBarArr" class="tab-item"
        :class="{ bigItem: item.routeName == $route.name }" :key="item.id">
        <template #icon="props">
          <img :src="props.active ? item.selected : item.normal" />
        </template>
        {{ $t(item.name) }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  export default {
    name: "tabbar",
    data() {
      return {
        show: true,
        tabBarArr: [
          {
            id: 0, //ID号
            name: '首页', //名称
            path: "/",
            routeName: "/", //路由名称
            normal: require("@/assets/img/ii.png"), //未选中时图标
            selected: require("@/assets/img/ia.png"), //选中时图标
          },
          {
            id: 1,
            name: '兑换专区',
            path: "/shop",
            routeName: "shop",
            normal: require("@/assets/img/sh.png"),
            selected: require("@/assets/img/sho.png"),
          },
          {
            id: 2,
            name: '钱包转账',
            path: "/turn",
            routeName: "turn",
            normal: require("@/assets/img/lll.png"),
            selected: require("@/assets/img/la.png"),
          },
          {
            id: 3,
            name: '资产',
            path: "/wallet",
            routeName: "wallet",
            normal: require("@/assets/img/zz.png"),
            selected: require("@/assets/img/za.png"),
          },
          {
            id: 4,
            name: '我的',
            path: "/mine",
            routeName: "mine",
            normal: require("@/assets/img/mm.png"),
            selected: require("@/assets/img/ma.png"),
          }
        ],
        isShowBottom: true, //显示或者隐藏footer
        documentHeight: document.documentElement.clientHeight, //默认屏幕高度
      }
    },
    watch: {
      lang: {
        handler() {
          this.show = false
          setTimeout(() => {
            this.show = true
          }, 10);
        },
        deep: true,
        immediate: true,
      }
    },
    computed: {
      active: {
        get: function () {
          let obj = this.tabBarArr.find(val => val.routeName == this.$route.name)
          return obj.id || 0
        },
        set: function (data) { },
      },
      lang() {
        return this.getLang()
      }
    },
    methods: {},
    mounted() {
      window.onresize = () => {
        return (() => {
          if (this.documentHeight > document.documentElement.clientHeight) {
            this.isShowBottom = false
          } else {
            this.isShowBottom = true
          }
        })()
      }
    },
  }
</script>
<style lang="less" scoped>
  .fade-three-enter-active,
  .fade-three-leave-active {
    transition: opacity 0.15s;
  }
  .fade-three-enter,
  .fade-three-leave-to
  /* .fade-three-leave-active below version 2.1.8 */
    {
    opacity: 0;
  }
  /deep/.van-tabbar {
    background: #1B172E;
    .van-tabbar-item {
      background: #1B172E;
      .van-tabbar-item__text {
        color: @tab_in_color;
      }
    }
    .van-tabbar-item--active {
      .van-tabbar-item__text {
        color: @tab_ac_color;
      }
    }
  }
</style>